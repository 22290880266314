<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="images-container">
    <div class="header">
      <div class="mobile-wrapper">
        <div class="header-background"
             :style="{backgroundImage: 'url(https://dmaegt2h1boun.cloudfront.net/images/SD_EBWM_Headerbild_Bilder_2019_01.jpg)'}">
          <div class="header-title-container">
            <h1>{{ $t("images.title") }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="images-content-wrapper">
      <v-container class="pt-10">
        <v-row>
          <v-col cols="12" style="padding: 6px;">
            <v-tabs v-model="tab">
              <v-tab v-for="tab in tabs" :key="tab.label">
                {{ tab.label }}
              </v-tab>
              <v-tabs-slider style="display: none"/>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row class="pb-5">
          <v-col cols="12">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="tab in tabs" :key="tab.value">
                <v-card flat v-if="tab.value === 0">
                  <v-card-text v-if="images.woerthersee.length > 0">
                    <v-row>
                      <v-col cols="12">
                        <light-gallery
                          @close="images.control.woerthersee = null"
                          :interfaceColor="'#33E39A'"
                          :disableScroll="true"
                          :index="images.control.woerthersee"
                          :images="images.woerthersee"/>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <v-row>
                          <v-col cols="4" md="3" lg="2" class="pa-1" v-for="n in 11" :key="n">
                            <v-img @click="images.control.woerthersee = n - 1"
                                   style="cursor: pointer" aspect-ratio="1"
                                   :src="'https://res.cloudinary.com/specialdeal/image/upload/w_300,f_auto,q_auto:eco/v1603793360/ebikecup/woerthersee/IMG_' + n + '.jpg'"
                                   :lazy-src="'https://res.cloudinary.com/specialdeal/image/upload/w_300,f_auto,q_auto:eco/v1603793360/ebikecup/woerthersee/IMG_' + n + '.jpg'">
                              <template v-slot:placeholder>
                                <v-layout class="fill-height align-center justify-center ma-0">
                                  <v-progress-circular indeterminate
                                                       color="grey lighten-5">
                                  </v-progress-circular>
                                </v-layout>
                              </template>
                            </v-img>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { LightGallery } from 'vue-light-gallery';

export default {
  name: 'images',
  components: {
    LightGallery,
  },
  data() {
    return {
      tab: undefined,
      tabs: [
        { value: 0, label: this.$t('images.woerthersee') },
      ],
      images: {
        control: {
          woerthersee: null,
        },
        expand: {
          woerthersee: false,
        },
        woerthersee: [],
      },
    };
  },
  mounted() {
    this.addWoertherseeImages();
  },
  methods: {
    addWoertherseeImages() {
      for (let i = 1; i <= 11; i += 1) {
        this.images.woerthersee.push({
          title: this.$t('images.woerthersee'),
          url: 'https://res.cloudinary.com/specialdeal/image/upload/c_limit,h_1480,q_auto:eco/v1603793360/ebikecup/woerthersee/IMG_'.concat(i).concat('.jpg'),
        });
      }
    },
    range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    },
  },
};
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";

  .images-container {
    font-family: $default-font;
    font-weight: lighter;
    .header {
      position: relative;
      height: 500px;
      .header-background {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 500px;
        position: fixed;
        z-index: 0;

        .header-title-container {
          position: absolute;
          bottom: 50px;
          left: 200px;
          @include MQ(XS) {
            left: 10px;
            bottom: 25px;
          }
          @include MQ(S) {
            left: 50px;
            bottom: 25px;
          }
          @include MQ(M) {
            left: 50px;
            bottom: 25px;
          }
          @include MQ(L) {
            left: 150px;
            bottom: 35px;
          }
          h1 {
            font-style: italic;
            color: white;
            font-size: 72px;
            line-height: 72px;
            text-align: left;
            span {
              color: $primary;
            }
            @include MQ(XS) {
              font-size: 38px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            @include MQ(S) {
              font-size: 48px;
              line-height: 48px;
              margin-bottom: 10px;
            }
            @include MQ(M) {
              font-size: 48px;
              line-height: 48px;
            }
            @include MQ(L) {
              font-size: 62px;
              line-height: 62px;
            }
          }
        }
      }
    }

    .images-content-wrapper {
      position: relative;
      background-color: white;
      .v-tabs {
        .v-tabs-bar {
          .v-slide-group__wrapper {
            .v-tab {
              font-family: $default-font;
              border: 2px solid $primary;
              color: $primary-text;
              font-weight: 600;
              padding: 0 10px;
              font-size: 18px;
              text-transform: none;
              margin-right: 15px;
              &.v-tab--active {
                background-color: $primary;
                color: white;
              }
            }
          }
        }
        .v-item-group {
          .v-slide-group__prev {
            display: none !important;
          }
        }
      }
      h1 {
        font-weight: 400;
        color: $primary-text;
        margin-bottom: 15px;
      }
    }

  }
</style>
